import React, {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from "react";
import {
    AudioFileInfoDTO,
    AudioTaskResponseCommentDTO, AudioTaskResponseCommentListDTO,
    ManualTaskResponseDTO,
    TaskCheckingResultStatusDTO,
    TaskCheckingService
} from "../../../generated/api";
import {Button, CircularProgress, Typography} from "@mui/material";
import MuiTextField from "@mui/material/TextField"
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import {LoadingButton} from "@mui/lab";
import TinyMceEditorWrapper from "../../wrappers/TinyMceEditorWrapper";

interface ITaskUserManualAudioResponseProps {
    taskResult: TaskCheckingResultStatusDTO
    loadTaskResult?: () => void;
}

const ButtonAdd = styled(Button)({
    backgroundColor: 'rgba(38, 51, 64, 1)',
    color: 'white',
    borderRadius: '8px',
    padding: '4px 17px',
    boxShadow: 'none',

    ':hover': {
        backgroundColor: 'rgb(27,36,45)',
        boxShadow: 'none',
    }
})

const TextField = styled(MuiTextField)({
    '.MuiOutlinedInput-root': {
        borderRadius: '8px'
    }
})

interface ITaskAudioResponseFieldProps {
    item: AudioTaskResponseCommentDTO
    audioFile: AudioFileInfoDTO
    taskResult: TaskCheckingResultStatusDTO
    loadTaskResult?: () => void;
    setComments: Dispatch<SetStateAction<Record<number, Array<AudioTaskResponseCommentDTO>>>>
    comments: Array<AudioTaskResponseCommentDTO>
    audioComments: Array<AudioTaskResponseCommentListDTO>
}


const TaskAudioResponseField: React.FC<ITaskAudioResponseFieldProps> = (props) => {
    const {item, taskResult, setComments, loadTaskResult, audioFile, audioComments} = props
    const [text, setText] = useState(item.text || '')
    const [loading, setLoading] = useState(false)
    const [removeLoading, setRemoveLoading] = useState(false)
    const [error, setError] = useState('')

    const time = useMemo(() => {
        if (typeof item.second === 'number') {
            const minutes = Math.floor(item.second / 60)
            const seconds = Math.floor(item.second) % 60
            const secondsStr = seconds > 9 ? seconds : `0${seconds}`

            return `${minutes}:${secondsStr}`
        }

        return ''
    }, [item])

    const isDisabledSaveBtn = useMemo(() => {
        return !text || item.text === text
    }, [item, text])

    const onSave = useCallback(async () => {
        setLoading(true)
        setError('')

        if (taskResult.id) {
            const savedAudioComments = audioComments.find(el => {
                return el.audioId === audioFile.id
            })

            const savedAudioCommentsItem = savedAudioComments?.comments?.find((el => {
                return el.id === item.id
            }))

            if (savedAudioCommentsItem?.id) {
                await TaskCheckingService.patchAppApiEducationtaskTaskcheckingChangemanualresponsecomment({
                    id: String(taskResult.id),
                    commentId: savedAudioCommentsItem.id,
                    requestBody: {
                        text: text,
                    },
                }).catch((e) => {
                    if (e.body?.message) {
                        setError(e.body.message)
                    }
                })
            } else {
                await TaskCheckingService.postAppApiEducationtaskTaskcheckingAddaudiomanualresponsecomment({
                    id: String(taskResult.id),
                    requestBody: {
                        audioId: Number(audioFile.id) || null,
                        second: typeof item.second === 'number' ? item.second : null,
                        text: text
                    }
                }).then((res) => {
                    setComments(prevState => {
                        const state = {...prevState}

                        if (audioFile.id && state[audioFile.id]) {
                            state[audioFile.id] = state[audioFile.id].map((el) =>{
                                if (el.id === item.id) {
                                    return res
                                }

                                return el
                            })
                        }

                        return state
                    })
                }).catch((e) => {
                    if (e.body?.message) {
                        setError(e.body.message)
                    }
                })
            }
        }

        if (loadTaskResult) {
            await loadTaskResult()
        }

        setLoading(false)
    }, [taskResult, item, text])

    const onRemove = useCallback(async () => {
        setRemoveLoading(true)

        const savedAudioComments = audioComments.find(el => {
            return el.audioId === audioFile.id
        })

        const savedAudioCommentsItem = savedAudioComments?.comments?.find((el => {
            return el.id === item.id
        }))


        if (taskResult && item.id && savedAudioCommentsItem) {
            await TaskCheckingService.deleteAppApiEducationtaskTaskcheckingRemovemanualresponsecomment({
                id: String(taskResult.id),
                commentId: item.id
            })
        }

        setComments(prevState => {
            const state = {...prevState}

            if (audioFile.id && state[audioFile.id]) {
                state[audioFile.id] = state[audioFile.id].filter((el) => el.id !== item.id)
            }

            return state
        })

        if (loadTaskResult) {
            await loadTaskResult()
        }

        setRemoveLoading(false)
    }, [taskResult, item])

    return (
        <div>
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item alignItems={'center'}>
                    <Typography fontWeight={'500'} fontSize={'16px'}>
                        {`Комментарий к `}

                        <span style={{color: 'rgba(29, 138, 254, 1)'}}>
                            {time}
                        </span>
                    </Typography>
                </Grid>

                <Grid item>
                    <Grid container padding={'12px 0'} justifyContent={'flex-end'}>
                        <Grid item marginRight={'8px'}>
                            <LoadingButton
                                size={'small'}
                                loading={removeLoading}
                                onClick={onRemove}
                                color={'error'}
                                style={{
                                    borderRadius: '8px',
                                    boxShadow: 'none',
                                    color: 'rgba(255, 8, 41, 1)'
                                }}
                            >
                                Удалить
                            </LoadingButton>
                        </Grid>

                        <Grid item>
                            <LoadingButton
                                size={'small'}
                                loading={loading}
                                onClick={onSave}
                                disabled={isDisabledSaveBtn}
                                color={'primary'}
                                variant="contained"
                                style={{
                                    borderRadius: '8px',
                                    boxShadow: 'none',
                                    backgroundColor: isDisabledSaveBtn ? 'rgba(0,0,0,0.2)' : 'rgba(29, 138, 254, 1)'
                                }}
                            >
                                Сохранить
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <div>
                {loading ? (
                    <div style={{
                        height: '58px',
                        borderRadius: '10px',
                        border: '2px solid #eee',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <TinyMceEditorWrapper
                        autoSaveInterval={1}
                        minimal={true}
                        height={48}
                        initialValue={text}
                        onChange={(value) => {
                            setText(value)
                        }}
                        quickbarsInsertToolbar={''}
                        quickbarsSelectionToolbar={'bold italic underline strikethrough'}
                        onPaste={(event) => {
                            // Получаем данные из буфера обмена
                            const clipboardData = event.clipboardData

                            if (clipboardData) {
                                const files = clipboardData.files;

                                if (files.length > 0) {
                                    alert('Нельзя вставлять файл в поле для текста!')

                                    event.preventDefault()
                                }
                            }
                        }}
                    />
                )}

                <div style={{padding: '4px 0'}}>
                    <Typography color={'rgba(255, 8, 41, 1)'} fontSize={'12px'}>
                        {error}
                    </Typography>
                </div>
            </div>
        </div>
    )
}

const TaskUserManualAudioResponse: React.FC<ITaskUserManualAudioResponseProps> = (props) => {
    const {taskResult, loadTaskResult} = props
    const [comments, setComments] = useState<Record<number, Array<AudioTaskResponseCommentDTO>>>({})
    const [timeSeconds, setTimeSeconds] = useState(0)

    const lastResponse = useMemo(() => {
        return taskResult?.lastResponse as ManualTaskResponseDTO
    }, [taskResult])

    const audioFiles = useMemo(() => {
        return lastResponse?.audioFiles || []
    }, [lastResponse])

    const audioComments = useMemo(() => {
        if (lastResponse.audioComments) {
            return lastResponse.audioComments
        }

        return []
    }, [lastResponse])

    const isBlock = useCallback((id: number) => {
        if (comments && comments[id]) {
            return comments[id].some((el) => el.second === timeSeconds)
        }

        return false
    }, [comments, timeSeconds])

    useEffect(() => {
        audioComments.forEach((el) => {
            if (el.audioId && el.comments) {
                if (comments[el.audioId]) {
                    const items = comments[el.audioId].filter(comm => !el.comments?.some(element => element.id === comm.id)) || []

                    setComments(prevState => {
                        const state = {
                            ...prevState
                        }

                        // @ts-ignore
                        state[el.audioId] = [...items, ...el.comments]

                        return state
                    })
                } else {
                    setComments(prevState => {
                        const state = {
                            ...prevState
                        }

                        // @ts-ignore
                        state[el.audioId] = el.comments

                        return state
                    })
                }
            }
        })

    }, [audioComments])

    return (
        <div>
            {audioFiles?.map((item, key, array) => {
                const id = `btn_add_commentId:${item.id}`
                const audioId = `audio:${item.id}`
                const audioCommentsItems = (item.id ? comments[item.id] || [] : []).sort((a, b) => (a.second || 0) - (b.second || 0))

                console.log(audioCommentsItems)

                if (!item.url) return null

                return (
                    <div key={item.id} style={{
                        marginBottom: array.length - 1 === key ? '0' : '24px'
                    }}>
                        <div style={{display: 'flex', marginBottom: '8px'}}>
                            <audio
                                id={audioId}
                                src={item.url}
                                controls
                                preload='auto'
                                onTimeUpdate={(e) => {
                                    const target = e.target as HTMLAudioElement
                                    const currentTime = Math.floor(target.currentTime)

                                    const btn = document.getElementById(id)

                                    const minutes = Math.floor(currentTime / 60)
                                    const seconds = Math.floor(currentTime) % 60
                                    const secondsStr = seconds > 9 ? seconds : `0${seconds}`

                                    if (seconds !== timeSeconds) {
                                        setTimeSeconds(seconds)
                                    }

                                    if (btn) {
                                        btn.dataset.time = String(currentTime)
                                        btn.innerHTML = `Добавить комментарий к ${minutes}:${secondsStr}`
                                    }
                                }}
                                style={{
                                    width: '572px'
                                }}
                            />


                        </div>

                        <div>
                            <ButtonAdd
                                id={id}
                                variant={'contained'}
                                size={'small'}
                                onClick={(e) => {
                                    const target = e.target as HTMLButtonElement
                                    const currentTime = target.dataset.time ? Number(target.dataset.time) : 0

                                    if (item.id && isBlock(item.id)) return

                                    const audioElement = document.getElementById(audioId) as HTMLAudioElement

                                    if (audioElement) {
                                        audioElement.pause()
                                    }

                                    setComments(prevState => {
                                        const state = {
                                            ...prevState
                                        }

                                        const newCommit = {
                                            second: currentTime,
                                            id: Date.now(),
                                            text: '',
                                            commentAt: new Date().toString()
                                        }

                                        const items = item.id ? comments[item.id] || [] : []

                                        // @ts-ignore
                                        state[item.id] = [...items, newCommit]

                                        return state
                                    })
                                }}
                            >
                                {`Добавить комментарий`}
                            </ButtonAdd>

                            <div>
                                <Typography color={'red'}>

                                </Typography>
                            </div>
                        </div>

                        <div>
                            {audioCommentsItems.map((commentItem) => {
                                return (
                                    <TaskAudioResponseField
                                        key={commentItem.id}
                                        taskResult={taskResult}
                                        audioFile={item}
                                        item={commentItem}
                                        comments={audioCommentsItems}
                                        setComments={setComments}
                                        loadTaskResult={loadTaskResult}
                                        audioComments={audioComments}
                                    />
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default TaskUserManualAudioResponse