import * as React from 'react';
import {Box, CircularProgress, createTheme, Grid, Skeleton, Stack, useMediaQuery} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import useProfile from "./helpers/useProfile";
import {EmployeeProfileDTO} from "./generated/api";
import routing from "./Routing";
import {useCallback, useState} from "react";
import 'dayjs/locale/ru';
import {LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Layout from "./layout/Layout";
import globalTheme from "./styles/globalTheme";
import ErrorLoadingChunk from "./ErrorLoadingChunk";

const PageSuspense = (props: any) => (
    <React.Suspense fallback={<Box sx={{ mx: 'auto' }}><CircularProgress /></Box>}>
      {props.children}
    </React.Suspense>
)

export const ProfileContext = React.createContext<EmployeeProfileDTO|null>(null);

export default function App() {
  const theme = React.useMemo(
      () =>
          globalTheme,
      [],
  );

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const preparedRouting = routing.map((route: any) => {
    if (route.parentPath) {
        route.parent = routing.find((r: any) => r.path === route.parentPath);
    }
    return route;
  })

  const getRouteChain = useCallback((params: any, route: any, chain: any[]): {name: string, path: string }[] => {
    let path = route.path
    Object.keys(params).forEach((key) => {
      path = path.replace(`:${key}`, params[key]);
    })
    chain.unshift({
      name: route.name,
      path,
    });
    if (!route.parent) {
      return chain;
    }
    return getRouteChain(params, route.parent, chain);
  }, [])

  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: preparedRouting.filter(route => !route.withoutLayout).map((route: any) => ({
        ...route,
        element: (
            <ErrorLoadingChunk>
              <PageSuspense>{route.element}</PageSuspense>
            </ErrorLoadingChunk>
        ),
        handle: { crumb: (data: any, params: any) => getRouteChain(params, route, []) },
      }))
    },
    {
      children: preparedRouting.filter(route => route.withoutLayout).map((route: any) => ({
        ...route,
        element: (
            <ErrorLoadingChunk>
             <PageSuspense>{route.element}</PageSuspense>
            </ErrorLoadingChunk>
        ),
        handle: { crumb: (data: any, params: any) => getRouteChain(params, route, []) },
      }))
    },
  ]);


  const {profile, isLoading: profileLoading} = useProfile();

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
        {profileLoading
            ?
            <Grid container sx={{height: '100vh'}}>
              {/*{isSmUp*/}
              {/*    ? <Grid item sx={{p: 1}}>*/}
              {/*        <Stack spacing={1}>*/}
              {/*          {Array.from(new Array(8)).map((_, index) => (*/}
              {/*              <Skeleton key={index} variant="rounded" width={256} height={48} />*/}
              {/*          ))}*/}
              {/*        </Stack>*/}
              {/*      </Grid>*/}
              {/*    : null }*/}
              <Grid item xs sx={{p: 1}}>
                <Grid spacing={1} container direction='column' sx={{'height': '100%'}}>
                  <Grid item><Skeleton variant="rectangular" width={'100%'} height={56} /></Grid>
                  <Grid item xs><Skeleton variant="rectangular" width={'100%'} height={'100%'}/></Grid>
                </Grid>
              </Grid>
            </Grid>
            :
            <ProfileContext.Provider value={profile || null}>
              <RouterProvider router={router} />
            </ProfileContext.Provider>}
        </LocalizationProvider>
    </ThemeProvider>
  );
}
