import {
    CircularProgress,
    Stack,
    Typography
} from "@mui/material";
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import TinyMceEditorWrapper from "../../wrappers/TinyMceEditorWrapper";
import {LoadingButton} from "@mui/lab";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    ApiError,
    ManualEmployeeTaskDTO,
    TaskCheckingResultStatusDTO,
    TaskCheckingService, TestResultTaskDTO
} from "../../../generated/api";
import CheckIcon from "@mui/icons-material/Check";
import defaultErrorHandle from "../../../helpers/DefaultErrorHandle";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import TaskUserTextResponse from "../TaskUserResponses/TaskUserTextResponse";
import TaskUserSelectionResponse from "../TaskUserResponses/TaskUserSelectionResponse";
import TaskUserManualTextResponse from "../TaskUserResponses/TaskUserManualTextResponse";
import TaskUserManualPhotoResponse from "../TaskUserResponses/TaskUserManualPhotoResponse";
import TaskUserManualAudioResponse from "../TaskUserResponses/TaskUserManualAudioResponse";
import ScoreRatingButton from "../ScoreRatingButton";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import 'mathlive/static.css'

interface TaskCheckingProps {
  taskResult: TaskCheckingResultStatusDTO;
  task?:  TestResultTaskDTO
  loadTaskResult?: (task: TestResultTaskDTO) => void;
  onCheckComplete?: () => void;
  onSetComment?: (text: string) => void
  onSetScore?: (score: number | null | undefined) => void
  isLoading?: boolean
}

const Accordion = styled(MuiAccordion)({
    ['&.MuiAccordion-root']: {
        margin: 0,
        boxShadow: 'none',
        borderBottom: '1px solid rgba(19, 25, 32, 0.08)',
        ['::before']: {
            height: 0,
        },
    }
})

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: '0 24px 24px 24px'
})

const AccordionSummary = styled(MuiAccordionSummary)({})

export default function TaskChecking(props: TaskCheckingProps) {
  const {
      taskResult,
      onCheckComplete,
      loadTaskResult,
      task: taskItem,
      onSetComment,
      onSetScore,
      isLoading
  } = props
  const task = taskResult.task
  const [errorText, setErrorText] = useState()
  const [comment, setComment] = useState<string>(taskResult.comment || '');
  const [score, setScore] = useState<number | null | undefined>(taskResult.score);
  const [isTaskCheckLoading, setIsTaskCheckLoading] = React.useState<boolean>(false);
  const [isSaved, setIsSaved] = useState(false)

  const onScoreChange = useCallback(( value: number) => {
    setScore(value)
  }, [setScore]);

  const onCommentChange = useCallback((comment: string) => {
    setComment(comment)
  }, [setComment]);

  const save = useCallback(() => {
    setIsTaskCheckLoading(true);
    TaskCheckingService.postAppApiEducationtaskTaskcheckingCheck({
          id: String(taskResult.id),
          requestBody: {
            score: score || 0,
            comment: comment
          },
        }
    )
        .then(() => {
          setIsSaved(true)
          taskResult.comment = comment
          taskResult.score = score
          if (onCheckComplete) onCheckComplete()
        })
        .catch((err: ApiError) => {
          setErrorText(err.body.message);
          defaultErrorHandle(err);
        })
        .finally(() => {
          setIsTaskCheckLoading(false);
        })
  }, [score, comment, task, taskResult, onCheckComplete]);

  useEffect(() => {
      setIsSaved(false)
  }, [comment, score, taskResult?.id])

  useEffect(() => {
      if (onSetComment) {
          onSetComment(comment)
      }
  },[comment])

    useEffect(() => {
        if (onSetScore) {
            onSetScore(score)
        }
    },[score])

  useEffect(() => {
     if (taskResult) {
        setComment(taskResult?.comment || '')

        setScore(typeof taskResult?.score === 'number' ? taskResult.score : null)
     }
  }, [taskResult])

  const disabledBtn = useMemo(() => {
      if (typeof score !== 'number') {
          return true
      } else if (isSaved) {
          return true
      }

      return taskResult.score === score && comment === (taskResult.comment || '')
  }, [score, isSaved, comment, taskResult])

  const manualTask = taskResult.task as ManualEmployeeTaskDTO

  const [conditionsExpanded, setConditionsExpanded] = useState(false)
  const [groupConditionsExpanded, setGroupConditionsExpanded] = useState(false)

  return <>
        {taskResult.task?.group?.content && (
            <>
                <Accordion expanded={groupConditionsExpanded}>
                    <AccordionSummary
                        onClick={() => {
                            setGroupConditionsExpanded((prevState => !prevState))
                        }}
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                            padding: '16px 24px',
                            height: '72px',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <div style={{minWidth: '136px'}}>
                            <Typography fontSize={'18px'} lineHeight={'32px'} fontWeight={500}>
                                Общее условие задачи
                            </Typography>
                        </div>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography variant="body1">
                            <div dangerouslySetInnerHTML={{__html: taskResult.task?.group?.content || ''}} />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </>
        )}

        <Accordion expanded={conditionsExpanded}>
          <AccordionSummary
              onClick={() => {
                  setConditionsExpanded((prevState => !prevState))
              }}
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                padding: '16px 24px',
                height: '72px',
                display: 'flex',
                alignItems: 'center'
              }}
          >
            <Typography fontSize={'18px'} lineHeight={'32px'} fontWeight={500}>
              Условие
            </Typography>

            {!conditionsExpanded && (
                <Typography  sx={{ color: 'text.disabled', ml: 2, lineHeight: '32px' }}>
                    { taskResult.task?.conditionsPreview }
                </Typography>
            )}
          </AccordionSummary>

            <AccordionDetails>
                <Typography variant="body1">
              <div
                  dangerouslySetInnerHTML={{__html: taskResult.task?.conditions || ''}}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>

      {taskResult.task?.solution && (
          <Accordion>
              <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                      padding: '16px 24px',
                      height: '72px',
                      display: 'flex',
                      alignItems: 'center'
                  }}
              >
                  <Typography fontSize={'18px'} lineHeight={'32px'} fontWeight={500}>
                      Решение
                  </Typography>
              </AccordionSummary>

              <AccordionDetails>
                  <Typography variant="body1">
                      <div dangerouslySetInnerHTML={{__html: taskResult.task?.solution || ''}}></div>
                  </Typography>
              </AccordionDetails>
          </Accordion>
      )}

      {taskResult.result === 'skipped' || !taskResult.lastResponse ? (
          <div style={{
              padding: '16px 24px',
              height: '72px',
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid rgba(19, 25, 32, 0.08)',
          }}>
              <Typography fontSize={'18px'} lineHeight={'32px'} fontWeight={500}>
                  Нет ответа
              </Typography>
          </div>
      ) : (
          <Accordion defaultExpanded>
              <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                      padding: '16px 24px',
                      height: '72px',
                      display: 'flex',
                      alignItems: 'center'
                  }}
              >
                  <Typography fontSize={'18px'} lineHeight={'32px'} fontWeight={500}>
                      Ответ
                  </Typography>
              </AccordionSummary>

              <AccordionDetails>
                  {taskResult.task?.type === 'text' && (
                      <>
                          <TaskUserTextResponse taskResult={taskResult} />
                      </>
                  )}

                  {taskResult.task?.type === 'selection' && (
                      <>
                          <TaskUserSelectionResponse taskResult={taskResult} />
                      </>
                  )}

                  {taskResult.task?.type === 'manual' && (
                      <>
                          {manualTask.withPhoto && (
                              <TaskUserManualPhotoResponse
                                  taskResult={taskResult}
                                  loadTaskResult={() => {
                                      if (loadTaskResult && taskItem) {
                                          loadTaskResult(taskItem)
                                      }
                                  }}
                              />
                          )}

                          {manualTask.withAudio && (
                              <TaskUserManualAudioResponse
                                  taskResult={taskResult}
                                  loadTaskResult={() => {
                                      if (loadTaskResult && taskItem) {
                                          loadTaskResult(taskItem)
                                      }
                                  }}
                              />
                          )}

                          {manualTask.withText && (
                              <TaskUserManualTextResponse
                                  taskResult={taskResult}
                                  loadTaskResult={() => {
                                      if (loadTaskResult && taskItem) {
                                          loadTaskResult(taskItem)
                                      }
                                  }}
                              />
                          )}
                      </>
                  )}
              </AccordionDetails>
          </Accordion>
      )}

      <Accordion defaultExpanded>
          <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                  padding: '16px 24px',
                  height: '72px',
                  display: 'flex',
                  alignItems: 'center'
              }}
          >
              <Typography fontSize={'18px'} lineHeight={'32px'} fontWeight={500}>
                  Комментарий
              </Typography>
          </AccordionSummary>

          <AccordionDetails>
              {!isLoading ? (
                  <div>
                      <TinyMceEditorWrapper
                          height={200}
                          initialValue={taskResult.comment}
                          onChange={(val) => onCommentChange(val)}
                          autoSaveInterval={1}
                      />
                  </div>
              ) : (
                  <div style={{
                      padding: '24px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '200px'
                  }}>
                      <CircularProgress />
                  </div>
              )}
          </AccordionDetails>
      </Accordion>

      <Stack sx={{padding: '16px 24px'}}>
          <Grid container justifyContent={'space-between'}>
              <Grid item>
                  <ScoreRatingButton
                      maxScore={taskResult.task?.score}
                      initialScore={score}
                      onScoreChange={onScoreChange}
                  />
              </Grid>

              <Grid item>
                  <LoadingButton
                      disabled={disabledBtn}
                      size={'small'}
                      loading={isTaskCheckLoading}
                      variant="contained"
                      onClick={() => save()}
                      startIcon={isSaved ? <CheckIcon color={'disabled'} /> : undefined}
                  >
                      {isSaved ? 'Изменения сохранены' : 'Сохранить'}
                  </LoadingButton>
              </Grid>
          </Grid>

          {errorText && (
            <div style={{
                padding: '12px 0'
            }}>
                <Typography textAlign={'right'} color={'red'} fontSize={'12px'}>
                    {errorText}
                </Typography>
            </div>
          )}
      </Stack>
  </>
}