import {Component, ReactNode} from "react";

class ErrorLoadingChunk extends Component<{ children?: ReactNode }> {

    componentDidMount() {
        // После монтирования компонента удаляем ключ
        localStorage.removeItem('errorLoadingChunk')
    }

    componentDidCatch(error: Error) {
        const isErrorLoadingChunk = localStorage.getItem('errorLoadingChunk')
        if (/Loading chunk .* failed./i.test(error.message) && !isErrorLoadingChunk) {
            // ЛС на тот случай если будет бесконечная перезагрузка
            localStorage.setItem('errorLoadingChunk', 'true')

            window.location.reload()
        }
    }

    render() {
        return this.props.children
    }
}

export default ErrorLoadingChunk