import React, {useContext, useEffect, useMemo, useState} from "react";
import Box from "@mui/material/Box";
import {Button, Dialog, DialogActions, DialogContent, Divider, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ProfileContext} from "../../App";
import {useUchebaProfile} from "../../helpers/useUchebaProfile";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import TelegramIcon from '@mui/icons-material/Telegram';

interface ITgAlertProviderProps {
    children: any
    open?: boolean
}

const TgAlertProvider: React.FC<ITgAlertProviderProps> = (props) => {
    const CHANNEL_ID = 4
    const {children} = props
    const [open, setOpen] = useState(false)
    const profile = useContext(ProfileContext)
    const {uchebaProfile} = useUchebaProfile()
    const [openDialog, setOpenDialog] = useState(false)

    const messangers = useMemo(() => {
        if (uchebaProfile) {
            return uchebaProfile.messengers
        }

        return null
    }, [uchebaProfile])

    const hasChannel = useMemo(() => {
        if (messangers) {
            return messangers.some((el) => el.channelId === CHANNEL_ID && el.isAllowed)
        }

        return null
    }, [messangers])

    useEffect(() => {
        if (profile?.cabinet?.id !== 1) {
            if (messangers) {
                if (!hasChannel) {
                    setOpenDialog(true)
                } else {
                    setOpenDialog(false)
                }
            }
        }
    }, [messangers, profile, hasChannel])

    useEffect(() => {
        if (profile?.cabinet?.id !== 1) {
            if (messangers) {
                if (!hasChannel && !openDialog) {
                    setOpen(true)
                } else {
                    setOpen(false)
                }
            }
        }
    }, [messangers, profile, hasChannel, openDialog])

    const href = useMemo(() => {
        return `https://www.ucheba.ru/events/tg-subscribe/registration?channelId=${CHANNEL_ID}&templateId=10118`
    }, [])

    return (
        <div style={{
            height: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column'
        }}>
            <div style={{
                minHeight: '100%'
            }}>
                {children}
            </div>

            <Dialog
                open={openDialog}
                onClose={() => {
                    setOpenDialog(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" fontSize={'24px'}>
                    Чтобы продолжить работу подключите наши уведомления в телеграме
                </DialogTitle>

                <Divider />

                <DialogContent>
                    <DialogContentText id="alert-dialog-description" padding={'24px'} color={'black'} fontSize={'14px'}>
                        Будем напоминать о предстоящих занятиях и сообщать о выполненных работах. Обещаем не спамить 🙂
                    </DialogContentText>
                </DialogContent>

                <Divider />

                <DialogActions style={{
                    padding: '24px'
                }}>
                    <Button target={'_blank'} href={href} color="primary" variant="contained" fullWidth endIcon={<TelegramIcon />}>
                        Подключить уведомления
                    </Button>
                </DialogActions>
            </Dialog>

            <div
                style={{
                    marginTop: '24px',
                    paddingBottom: '24px',
                    marginBottom: '-24px',
                    display: open ? 'block' : 'none',
                    width: '100%',
                    position: 'sticky',
                    backgroundColor: '#F5F7F9',
                    bottom: '0',
                    zIndex: '1',
                }}
            >
                <a target={'_blank'} href={href} style={{textDecoration: 'none'}}>
                    <Box
                        bgcolor={'rgba(29, 138, 254, 1)'}
                        padding={'16px'}
                        borderRadius={'8px'}
                        position={'relative'}
                        zIndex={10}
                        onClick={() => {
                            setOpen(false)
                        }}
                        sx={{
                            boxShadow: '0px 6px 7px -4px rgba(19, 25, 32, 0.08), 0px 11px 15px 1px rgba(19, 25, 32, 0.04), 0px 4px 20px 3px rgba(19, 25, 32, 0.08)'
                        }}
                    >
                        <Grid container justifyContent={'space-between'}>
                            <Grid item alignItems={'center'} display={'flex'}>
                                <Typography color={'white'} fontSize={'18px'} fontWeight={500} lineHeight={'24px'}>
                                    Подключить уведомления о начале занятий, выполненных работах и других важных событиях
                                </Typography>
                            </Grid>

                            <Grid item alignItems={'center'} display={'flex'}>
                                <TelegramIcon sx={{color: 'white'}} />
                            </Grid>
                        </Grid>
                    </Box>
                </a>
            </div>
        </div>
    )
}

export default React.memo(TgAlertProvider)