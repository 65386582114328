import {useEffect, useState} from "react";
import {IProfileUcheba} from "./types/IProfileUcheba";
import getToken from "./getToken";
import {logout} from "./logout";

interface IUseUchebaProfile {
    (): {
        uchebaProfile: IProfileUcheba | null
    }
}

export const useUchebaProfile: IUseUchebaProfile = () => {
    const [uchebaProfile, setProfile] = useState<IProfileUcheba | null>(null)

    useEffect(() => {
        const token = getToken()

        fetch(`${process.env.REACT_APP_API_UCHEBA_URL}/profile`, {
            headers: {
                'x-auth-token': token
            }
        }).then((res) => {
            return res.json()
        }).then((data: IProfileUcheba) => {
            // если demo user то удаляем куку
            if (!data.phone) {
                logout()
            }
            setProfile(data)
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    return {
        uchebaProfile
    }
}