import React, {FC} from "react";
import {Avatar, Typography} from "@mui/material";
import {stringAvatar} from "../bll/stringAvatar";
import {useNavigate} from "react-router-dom";

interface ITestCheckingResultTasksListHeaderProps {
    testName?: string
    userName?: string
    userPhone?: string | null
}

const TestCheckingResultTasksListHeader: FC<ITestCheckingResultTasksListHeaderProps> = (props) => {
    const {testName, userName, userPhone} = props

    const navigate = useNavigate()

    const onClickUserName = () => {
        const link = '/students?query='

        if(userPhone) {
            navigate(`${link}${userPhone}`)
        }
        if(!userPhone && userName) {
            navigate(`${link}${userName}`)
        }
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '16px 0', position: 'relative' }}>
            {testName && (
                <div>
                    <Typography fontSize={'32px'} fontWeight={500} component="h2" whiteSpace={'nowrap'} >
                        {testName}
                    </Typography>
                </div>
            )}

            {userName && (
                <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: '16px', right: 0 }}>
                    <div style={{
                        width: '40px',
                        height: '40px',
                        background: 'linear-gradient(0.25turn, rgba(245, 247, 249, 0), rgba(245, 247, 249, 1))'
                    }}>

                    </div>
                    <div onClick={onClickUserName}
                         style={{display: 'flex', alignItems: 'center', backgroundColor: 'rgba(245, 247, 249, 1)', cursor:'pointer'}}>
                        <div style={{paddingRight: '12px'}}>
                            <Avatar {...stringAvatar(userName, {
                                width: 40,
                                height: 40,
                                fontSize: '20px',
                                fontWeight: 600
                            })} />
                        </div>

                        <div>
                            <Typography fontSize={'32px'} fontWeight={400} whiteSpace={'nowrap'} sx={{color:'#1d8afe'}}>
                                {userName}
                            </Typography>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TestCheckingResultTasksListHeader